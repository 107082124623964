@charset "UTF-8";
/* ========================================================
変数
======================================================== */
/* ========================================================
mixin
======================================================== */
/* ========================================================
変数
======================================================== */
/* ========================================================
	contact.css => ご相談・お問合せ用CSS
======================================================== */
.pagetitle-section {
  background-image: url(../../images/contact/bg_pagetitle.png);
  background-size: 505px 544px;
  background-position: right -63px bottom -203px;
}

.registrationform-section {
  padding: 50px 0 90px;
}

.registrationform-flow {
  margin-top: 0;
}

.privacy {
  width: 720px;
  margin: 0 auto;
}

.privacy-section {
  padding-bottom: 75px;
}

.privacy__item + .privacy__item {
  margin-top: 30px;
}

.privacy__heading {
  font-size: 1.8rem;
  color: #509967;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-weight: bold;
  padding: calc(0px - 1.8rem * (1.7 - 1) / 2) 0px calc(20px - 1.8rem * (1.7 - 1) / 2);
  border-bottom: 1px solid #509967;
}

.privacy__text {
  line-height: 2;
}

.privacy__heading + .privacy__text {
  margin: calc(20px - 16px * (1.7 - 1) / 2) 0px calc(0px - 16px * (1.7 - 1) / 2) 0px;
}

.privacy__notes {
  background-color: #ECECEC;
  padding: 20px;
  margin-top: 20px;
}

.privacy__notes p + p {
  margin-top: 5px;
}

.listnum {
  padding: 0;
  counter-reset: number;
  margin-top: 10px;
}

.listnum li {
  line-height: 2;
  position: relative;
  font-size: 1.6rem;
  padding-left: 1.5em;
  margin: calc(12px - 1.6rem * (2 - 1) / 2) 0px calc(0px - 1.6rem * (2 - 1) / 2) 0px;
}

.listnum li::before {
  counter-increment: number;
  content: counter(number) ".";
  color: #509967;
  font-size: 1.6rem;
  line-height: 1;
  width: 1em;
  height: 15px;
  position: absolute;
  left: 4px;
  top: 8px;
}

.sitemap {
  display: flex;
  justify-content: space-between;
}

.sitemap-section {
  padding-bottom: 80px;
}

.sitemap__column {
  width: calc(100% - 3);
}

.sitemap__item {
  margin: calc(15px - 16px * (1.7 - 1) / 2) 0px calc(0px - 16px * (1.7 - 1) / 2) 0px;
}

.sitemap__item--large {
  margin: calc(40px - 1.8rem * (1.7 - 1) / 2) 0px calc(0px - 1.8rem * (1.7 - 1) / 2) 0px;
}

.sitemap__item--large + .sitemap__item {
  margin: calc(30px - 16px * (1.7 - 1) / 2) 0px calc(0px - 16px * (1.7 - 1) / 2) 0px;
}

.sitemap__item--large + .sitemap__item--large {
  margin: calc(40px - 16px * (1.7 - 1) / 2) 0px calc(0px - 16px * (1.7 - 1) / 2) 0px;
}

.sitemap__link {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-weight: normal;
  font-size: 1.6rem;
  position: relative;
}

.sitemap__link::before {
  content: "・";
}

.sitemap__item--large .sitemap__link {
  font-size: 1.8rem;
  font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
  font-weight: bold;
}

.sitemap__item--large .sitemap__link::before {
  content: none;
}

@media screen and (max-width: 768px) {
  .pagetitle-section {
    background-size: 61% auto;
    background-position: right -138px bottom -94px;
  }
  .registrationform-section {
    padding: 30px 8% 40px;
  }
  .registrationform-flow {
    margin-top: 0;
  }
  .lower .section {
    padding-left: 8%;
    padding-right: 8%;
  }
  .privacy {
    width: 100%;
  }
  .privacy-section {
    padding: 0 8% 35px;
  }
  .sitemap {
    flex-direction: column;
  }
  .sitemap-section {
    padding-bottom: 50px;
  }
  .sitemap__column {
    width: 100%;
  }
}
