@charset "utf-8";

/* ========================================================
変数
======================================================== */

$img_path: '../images';

/* ========================================================
mixin
======================================================== */
@mixin lineMargin($margin, $fontSize: 16px, $lineHeight: 1.7){
  @if length($margin) == 1{
    margin: calc(#{$margin} - #{$fontSize} * (#{$lineHeight} - 1) / 2) $margin;
  }
  @else if length($margin) == 2{
    margin: calc(#{nth($margin, 1)} - #{$fontSize} * (#{$lineHeight} - 1) / 2) nth($margin, 2);
  }
  @else if length($margin) == 3{
    margin: calc(#{nth($margin, 1)} - #{$fontSize} * (#{$lineHeight} - 1) / 2) nth($margin, 2) calc(#{nth($margin, 3)} - #{$fontSize} * (#{$lineHeight} - 1) / 2);
  }
  @else{
    margin: calc(#{nth($margin, 1)} - #{$fontSize} * (#{$lineHeight} - 1) / 2) nth($margin, 2) calc(#{nth($margin, 3)} - #{$fontSize} * (#{$lineHeight} - 1) / 2) nth($margin, 4);
  }
}
@mixin lineMargint($margin, $fontSize: 16px, $lineHeight: 1.7){
  margin-top: calc(#{$margin} - #{$fontSize} * (#{$lineHeight} - 1) / 2);
}
@mixin lineMarginb($margin, $fontSize: 16px, $lineHeight: 1.7){
  margin-bottom: calc(#{$margin} - #{$fontSize} * (#{$lineHeight} - 1) / 2);
}
@mixin linePadding($padding, $fontSize: 16px, $lineHeight: 1.7){
  @if length($padding) == 1{
    padding: calc(#{$padding} - #{$fontSize} * (#{$lineHeight} - 1) / 2) $padding;
  }
  @else if length($padding) == 2{
    padding: calc(#{nth($padding, 1)} - #{$fontSize} * (#{$lineHeight} - 1) / 2) nth($padding, 2);
  }
  @else if length($padding) == 3{
    padding: calc(#{nth($padding, 1)} - #{$fontSize} * (#{$lineHeight} - 1) / 2) nth($padding, 2) calc(#{nth($padding, 3)} - #{$fontSize} * (#{$lineHeight} - 1) / 2);
  }
  @else{
    padding: calc(#{nth($padding, 1)} - #{$fontSize} * (#{$lineHeight} - 1) / 2) nth($padding, 2) calc(#{nth($padding, 3)} - #{$fontSize} * (#{$lineHeight} - 1) / 2) nth($padding, 4);
  }
}
@mixin linePaddingb($padding, $fontSize: 16px, $lineHeight: 1.7){
  padding-bottom: calc(#{$padding} - #{$fontSize} * (#{$lineHeight} - 1) / 2);
}
@mixin fontnormal{
    font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
    font-weight: normal;
}
@mixin fontbold{
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;
    font-weight: bold;
}
@mixin fontmplus{
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: 300;
}
@mixin fontmplusEB{
  font-family: 'M PLUS Rounded 1c', sans-serif;
  font-weight: 800;
}
@mixin fontMontserrat{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-style: normal;
}
@mixin opacity($opacity) {
  opacity: $opacity;
  $opacityIE: $opacity * 100;
  filter: alpha(opacity=$opacityIE);
}
@mixin opacity_imp($opacity) {
  opacity: $opacity !important;
  $opacityIE: $opacity * 100 !important;
  filter: alpha(opacity=$opacityIE) !important;
}
@mixin triangle($size, $color, $direction) {
  width: 0;
  height: 0;
  border: $size solid transparent;
  @if($direction == "top") {
    border-bottom: $size solid $color;
  } @elseif($direction == "bottom") {
    border-top: $size solid $color;
  } @elseif($direction == "left") {
    border-right: $size solid $color;
  }@elseif($direction == "right") {
    border-left: $size solid $color;
  }
}
@mixin triangle2($color, $direction) {
  @if($direction == "bottom") {
    background:
      linear-gradient(to top right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/50% 100%,
      linear-gradient(to top left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top right/50% 100%;
  } @elseif($direction == "top") {
    background:
      linear-gradient(to bottom right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/50% 100%,
      linear-gradient(to bottom left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top right/50% 100%;
  } @elseif($direction == "left") {
    background:
      linear-gradient(to top right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat bottom left/100% 50%,
      linear-gradient(to bottom right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top right/100% 50%;
  }@elseif($direction == "right") {
    background:
      linear-gradient(to bottom left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/100% 50%,
      linear-gradient(to top left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat bottom right/100% 50%;
  }@elseif($direction == "topRight") {
    background:
      linear-gradient(to top right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/100% 100%;
  }@elseif($direction == "topLeft") {
    background:
      linear-gradient(to top left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/100% 100%;
  }@elseif($direction == "bottomRight") {
    background:
      linear-gradient(to bottom right, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/100% 100%;
  }@elseif($direction == "bottomLeft") {
    background:
      linear-gradient(to bottom left, rgba(255,255,255,0) 50%, $color 50.5%) no-repeat top left/100% 100%;
  }
}
@mixin arrow($size1, $size2, $color, $direction) {
  width: $size1;
  height: $size1;
  border: $size2 solid;
  @if($direction == "up") {
    border-color: $color $color transparent transparent;
    transform: rotate(-45deg);
  } @elseif($direction == "down") {
    border-color:  transparent transparent $color $color;
    transform: rotate(-45deg);
  } @elseif($direction == "left") {
    border-color:  transparent transparent $color $color;
  transform: rotate(45deg);
  }@elseif($direction == "right") {
    border-color: $color $color transparent transparent;
    transform: rotate(45deg);
  }
}