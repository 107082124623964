@charset "utf-8";

@import "../mixin.scss";

/* ========================================================
変数
======================================================== */

$img_path: '../../images/';
$current_img_path: '../../images/contact';

/* ========================================================
	contact.css => ご相談・お問合せ用CSS
======================================================== */

.pagetitle{
	&-section{
		background-image: url(#{$current_img_path}/bg_pagetitle.png);
		background-size: 505px 544px;
		background-position: right -63px bottom -203px;
	}
}
.registrationform{
	&-section{
		padding: 50px 0 90px;
	}
	&-flow{
		margin-top: 0;
	}
}
.privacy{
	width: 720px;
	margin: 0 auto;
	&-section{
		padding-bottom: 75px;
	}
	&__item{
		& + &{
			margin-top: 30px;
		}
	}
	&__heading{
		font-size: 1.8rem;
		color: #509967;
		@include fontbold;
		@include linePadding(0px 0px 20px,1.8rem);
		border-bottom: 1px solid #509967;
	}
	&__text{
		line-height: 2;
		.privacy__heading + &{
			@include lineMargin(20px 0px 0px 0px);
		}
	}
	&__notes{
		background-color: #ECECEC;
		padding: 20px;
		margin-top: 20px;
		p + p{
			margin-top: 5px;
		}
	}
}
.listnum{
	padding: 0;
	counter-reset: number;
	margin-top: 10px;
	li{
    line-height: 2;
    position: relative;
    font-size: 1.6rem;
    padding-left: 1.5em;
		@include lineMargin(12px 0px 0px 0px,1.6rem,2);
		&::before{
			counter-increment: number;
			content: counter(number)'.';
			color: #509967;
			font-size: 1.6rem;
			line-height: 1;
			width: 1em;
			height: 15px;
			position: absolute;
			left: 4px;
			top: 8px;
		}
	}
}
.sitemap{
	display: flex;
	justify-content: space-between;
	&-section{
		padding-bottom: 80px;
	}
	&__column{
		width: calc(100% - 3);
	}
	&__item{
		@include lineMargin(15px 0px 0px 0px);
		&--large{
			@include lineMargin(40px 0px 0px 0px,1.8rem);
			& + .sitemap__item{
				@include lineMargin(30px 0px 0px 0px);
			}
			& + .sitemap__item--large{
				@include lineMargin(40px 0px 0px 0px);
			}
		}
	}
	&__link{
		@include fontnormal;
		font-size: 1.6rem;
		position: relative;
		&::before{
			content: "・";
		}
		@at-root .sitemap__item--large &{
			font-size: 1.8rem;
			@include fontbold;
			&::before{
				content: none;
			}
		}
	}
}


@media screen and (min-width: 1920px) {
}
@media screen and (max-width: 768px) {
	.pagetitle{
		&-section{
			background-size: 61% auto;
			background-position: right -138px bottom -94px;
		}
	}
	.registrationform{
		&-section{
			padding: 30px 8% 40px;
		}
		&-flow{
			margin-top: 0;
		}
	}
	.lower .section{
		padding-left: 8%;
		padding-right: 8%;
	}
	.privacy{
		width: 100%;
		&-section{
			padding: 0 8% 35px;
		}
	}
	.sitemap{
		flex-direction: column;
		&-section{
			padding-bottom: 50px;
		}
		&__column{
			width: 100%;
		}
	}
}